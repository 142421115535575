<template>
  <div class="navigation-layout" :class="layouts.data.mode">
    <div class="head-panel">
      <v-toolbar class="navigation" flat dense height="44">
        <v-spacer></v-spacer>
        <v-toolbar-items class="action-container">
          <v-btn
            v-if="$route.path.indexOf('choose-loan-amount') === -1"
            icon
            small
            @click="onClickLeft"
          >
            <v-icon :color="layouts.data.mode === 'dark' ? 'white' : 'rgba(15, 19, 30)'">
              navigate_before
            </v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <div class="page-title">{{ layouts.data.title }}</div>
        <v-spacer></v-spacer>
        <v-toolbar-items class="action-container">
<!--          <wap-call-phone-icon-->
<!--            :color="layouts.data.mode === 'dark' ? 'white' : 'rgba(15, 19, 30)'"-->
<!--          ></wap-call-phone-icon>-->
        </v-toolbar-items>
      </v-toolbar>
    </div>

    <div class="main-panel">
      <div class="main-panel-head py-2">
        <v-card
          v-if="
            layouts.data.subtitle !== undefined &&
              layouts.data.subtitle.length > 0
          "
          tile
          flat
          class="page-subtitle"
          color="transparent"
        >
          <v-card-title class="subtitle pb-6">
            {{ layouts.data.subtitle }}
          </v-card-title>
        </v-card>
        <v-card
          class="mx-6 py-1 pl-4 same-day"
          v-if="layouts.sameDay.countDownDisplay"
          :color="layouts.data.mode === 'dark'? 'rgba(255,255,255,0.2)': 'var(--theme_secondary_opacity_high)'"
          flat
          @click="layouts.sameDay.showIntroduction = true"
        >
          <div>
            <div class="same-day-title">
              Time Remaining for Same Day Funding:
            </div>
            <van-count-down
              class="count-down f-s-22 f-w-900"
              :time="layouts.sameDay.time"
              @finish="countDownFinished"
            />
          </div>
          <sand-glass
            class="sand-glass"
            :primary-color="layouts.data.mode === 'dark' ? 'white' : 'var(--theme_secondary)'"
            secondary-color="var(--theme_primary)"
          ></sand-glass>
        </v-card>
      </div>

      <router-view ref="routerView"></router-view>
    </div>

    <!-- Introduction Same day -->
    <wap-bottom-sheet
      v-model="layouts.sameDay.showIntroduction"
      :persistent="true"
      title="Same Day Funding"
      :notification-sheet="true"
      @confirm="layouts.sameDay.showIntroduction = false"
    >
      <v-card-text class="f-s-14 f-w-400">
        We will fund your account with same day of loan approval. Unavoidable delay that occur due to bank
        holidays, processing schedule of your bank, processing errors, "acts of
        God." and/or "acts of terror" may extend the time for the deposit.
      </v-card-text>
    </wap-bottom-sheet>
    <!-- Update effective data tip -->
    <wap-bottom-sheet
      v-model="layouts.sameDay.changeDateSheet.display"
      :persistent="true"
      title="Cut Off Time Passed"
      :notification-sheet="true"
      @confirm="onClickChange"
    >
        <v-card-text>
          You have passed the cut off time for Same Day Funding. But don't
          worry, you can change the disbursement date of your loan and request
          the fund again.
        </v-card-text>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
// import WapCallPhoneIcon from '@/components/base/WapCallPhoneIcon'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { InfrastructureApi } from '@/api'
import { CountDown } from 'vant'
import SandGlass from '@/components/svg/SandGlass'
import moment from 'moment'

const tool = new Tool()
export default {
  name: 'NavigationLayout',
  components: {
    WapBottomSheet,
    [CountDown.name]: CountDown,
    SandGlass
  },
  data () {
    return {
      layouts: {
        data: {
          title: '',
          subtitle: '',
          mode: '',
          sameDayTip: ''
        },
        sameDay: {
          time: null,
          countDownDisplay: true,
          changeDateSheet: {
            display: false
          },
          showIntroduction: false,
          noCutDown: true
        }
      }
    }
  },
  watch: {
    $route () {
      this.layouts.data.title = this.$route.meta.title
      this.layouts.data.subtitle = this.$route.meta.subtitle
      this.layouts.data.mode = this.$route.meta.mode // 控制按钮颜色
      this.getCutDownTime()
    },
    'store.getters.getSubtitle' (val) {
      if (tool.isNotEmpty(val)) {
        this.layouts.data.subtitle = val
      }
    },
    '$store.getters.getLoan.effectiveDate': {
      handler () {
        this.getCutDownTime()
      }
    },
    '$store.getters.getDateTimePatterns': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          // this.showCutOffTimeTip()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.layouts.data.title = this.$route.meta.title
    setTimeout(() => {
      this.layouts.data.subtitle = this.$route.meta.subtitle
    }, 500)
    this.layouts.data.mode = this.$route.meta.mode
    this.getCutDownTime()
  },
  methods: {
    onClickLeft () {
      if (
        tool.isNotEmpty(this.$route.meta) &&
        tool.isNotEmpty(this.$route.meta.to)
      ) {
        this.$router.push(this.$route.meta.to)
      } else {
        this.$router.go(-1)
      }
    },
    onClickChange () {
      this.layouts.sameDay.changeDateSheet.display = false
      this.layouts.sameDay.countDownDisplay = false
      this.layouts.sameDay.showIntroduction = false
      if (this.$route.path === '/disbursement/choose-bank-account') {
        // 当同页面跳转（choose bank account 页面），触发router view 下子组件的方法
        this.$refs.routerView.getEffectiveDate()
        this.$refs.routerView.getDefaultDisbursementDate()
      } else {
        this.$router.push('/disbursement/choose-bank-account')
      }
    },
    // same day时间倒计时
    getCutDownTime () {
      InfrastructureApi.getCutDownTime(this.$store.getters.getPortfolioId, result => {
        if (tool.isNotEmpty(result)) {
          // result = '00:59:59'
          // 页面刚渲染时，time为空，会立即触发countDownFinished方法，所以加一层限制
          this.layouts.sameDay.noCutDown = false
          // 只有当天的倒计时才显示
          let isToday = null
          if (tool.isNotEmpty(this.$store.getters.getLoan.effectiveDate)) {
            const today = moment(new Date()).format('MM/DD/yyyy')
            isToday = moment(today).isSame(this.$store.getters.getLoan.effectiveDate)
          } else {
            isToday = true
          }
          // 指定页面并且小于一小时才展示
          this.layouts.sameDay.countDownDisplay = this.$route.meta.sameDayTip && result.substring(0, 2) === '00' && isToday
          const dateString = '1970-01-01' // 设置一个日期
          const dateTimeString = dateString + ' ' + result // 将日期和时间字符串组合在一起
          this.layouts.sameDay.time = moment.utc(dateTimeString, 'YYYY-MM-DD HH:mm:ss').valueOf() // 这里拿到的是1970 年 1 月 1 日 00:00:00 到 1970 年 1 月 1 日 + result的时间戳
        } else {
          this.layouts.sameDay.noCutDown = true
          this.layouts.sameDay.countDownDisplay = false
        }
      })
    },
    countDownFinished () {
      const control = this.$route.meta.changeDateTip // bank account 页面之前的流程不展示弹窗，直接关闭倒计时
      if (control && !this.layouts.sameDay.noCutDown) {
        this.layouts.sameDay.changeDateSheet.display = true
      } else {
        this.layouts.sameDay.countDownDisplay = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.navigation-layout
  & .main-panel
    margin-top: 44px
  //头部是z-index为1，为避免内容覆盖，整体向下移动，该像素就是头部的高度

  & .head-panel
    position: fixed
    top: 0
    width: 100%
    z-index: 1

    & .page-title
      width: 100%
      height: 44px
      line-height: 44px
      text-align: center

    & .navigation
      background-color: white

  & .page-subtitle
    & .subtitle
      font-size: 24px
      justify-content: center
  // 根据路由的mode来判定是否绑定dark属性
  &.dark
    & .main-panel
      margin-bottom: 74px
    // 底部z-index为1，高度74

    & .head-panel
      background-color: var(--theme_primary)

      & .page-title
        color: #F5F5F5

      & .navigation
        background-color: transparent

      & .action-container
        & .v-btn
          color: #F5F5F5

    & .main-panel-head
      background-color: var(--theme_primary)
      margin-top: -1px

      & .page-subtitle
        & .subtitle
          color: #FFFFFF

      & .same-day-title
        color: rgba(255, 255, 255, 0.6)
        font-size: 12px
        line-height: 20px

      & .count-down
        color: #FFFFFF
        letter-spacing: 2px

    & .round
      border-radius: 0 0 16px 16px

    & .main-content
      padding: 16px 24px

  &.light
    & .main-panel
      margin-bottom: 74px
      // 底部z-index为1，高度74

  // 签字页面的样式不需要margin-bottom
  & .none-footer

    & .main-panel
      margin-top: 44px

.same-day
  border-radius: 8px
  display: flex
  justify-content: space-between
  position: relative
  z-index: 1

.sand-glass
  position: absolute
  right: 0
  top: -55%

</style>

<style lang="sass">
.navigation-layout.dark
  & .head-content
    background-color: var(--theme_primary)
    margin-top: -1px

  & .main-content
    padding: 16px 24px

.navigation-layout
  & .round
    border-radius: 0 0 16px 16px

  & .main-content
    padding: 0 24px 16px 24px

  & .footer-wrap
    position: fixed
    bottom: 0
    z-index: 1
    padding: 12px 24px
    width: 100%
    background: white
    // 避免苹果手机向上拉的时候，该部分无背景色
</style>
